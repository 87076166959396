import Link from "./link";
import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import { domain, isDesktop, isMobile } from '../utils';

import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FormattedMessage } from "react-intl";
import Header from '../components/header';
import Markdown from 'markdown-to-jsx'
import classNames from "classnames";
import Carousel from "./wrappers/carousel";

SwiperCore.use([Navigation]);

const CaseListCarouselMobile = ({ caseStudies ,header, showSmallHeader , shouldNotLazyload, hasbodyImages, bodyImages}) => {
    const nextEl = useRef(null);
    const prevEl = useRef(null);
    const image = useRef(null);
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);

    const handleSlideChange = (swiper) => {
      setIsAtStart(swiper.isBeginning);
      setIsAtEnd(swiper.isEnd);
    };

  return (
    <div className={`case-list case-list--carousel ${showSmallHeader ? 'no-wrap': ''}  ${caseStudies && caseStudies.length  === 1 ? 'case-list--hide-swiper' : ''}`}>
       {showSmallHeader ? (
          <>
            <div className="row">
              <div className="col col-md-7">
                <header className={classNames("header")}>
                  <h1 className="header__heading header__heading--space">
                    {header && header.heading}
                  </h1>
                </header>
              </div>
              <div className="col offset-md-1 col-md-3">
                {""}
                  <div className="swiper-navigation-controls mobile">
                    <button ref={prevEl} className= {classNames("custom-swiper-button-prev swiper-button-prev", {"swiper-button-disabled": isAtStart})}></button>
                    <button ref={nextEl} className={classNames("custom-swiper-button-next swiper-button-next", {"swiper-button-disabled": isAtEnd })}></button>
                  </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {header && (
              <div className={"case-list__header-wrapper"}>
                  <header className="case-list__header header">
                    <h1 className="header__heading">
                      <small className="header__preheading">
                      {header && header.supHeading}
                      </small>
                      {header && header.heading}
                    </h1>
                  </header>
              </div>
            )}
          </>
        )}


      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation={{nextEl: nextEl.current, prevEl: prevEl.current}}
        onSlideChange={(swiper) => handleSlideChange(swiper)}
      >
        {bodyImages.map((bodyImage, i) => {
            return (
              <SwiperSlide>
                <article key={`bodyImage-${i}`} className="case-list__item case-list__item-small-margin">
                  <div className="row">
                    <div className="case-list__content case-list__content-sm-mt  col-md-5">
                      {bodyImage.title && (
                        <h1 className="case-list__heading">
                          {bodyImage.title}
                        </h1>
                      )}
                      <div className="case-list__body">
                        <Markdown options={{
                          overrides: {a: {
                            component: Link
                          },                           
                          }
                        }}>{bodyImage.richText}</Markdown>
                      </div>
                      </div>
                      <div className="case-list__image offset-md-1 col-md-5">
                        {(bodyImage.media && bodyImage.media.length > 1 )? (
                          <Carousel>
                            {bodyImage.media.map((media, index) => {
                              return <img className="plain-cards__image image--fluid" src={media.url} />
                            })}
                          </Carousel>
                        ) : (
                          <img className="plain-cards__image image--fluid" src={(bodyImage.media[0].url)} alt={bodyImage.media[0].alternativeText} />
                        )}
                      </div>
                  </div>
                </article >
              </SwiperSlide>
            )
           }) }
      </Swiper>
    </div>
  )
}

CaseListCarouselMobile.propTypes = {
  caseStudies: PropTypes.array
}

CaseListCarouselMobile.defaultProps = {
  caseStudies: []
}

export default CaseListCarouselMobile
