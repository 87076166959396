import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Pagination]);

const Carousel = ({children }) => {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      pagination={{ clickable: true, dynamicBullets: true }}
    >
      {children.map((child, index) => {
        return (
          <SwiperSlide>
            <div className="swiper-slide-inner">
              {child}
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  )
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Carousel
